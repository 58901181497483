import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './component/app/App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root');
if(container !== null) {
  const root = ReactDOM.createRoot(container);
  if (container.hasChildNodes()) {
    ReactDOM.hydrateRoot(container, <React.StrictMode><HelmetProvider><App /></HelmetProvider></React.StrictMode>);
  } else {
    root.render(<React.StrictMode><HelmetProvider><App /></HelmetProvider></React.StrictMode>);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
