import axios from "axios";
import ConcurrentActionHandler from 'handler/ConcurrentActionHandler';

const apiUrl = "https://api.nuraling.com";
const authUrl = "https://auth.nuraling.com";

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
  responseType: "json",
  withCredentials: true,
});

const concurrentActionHandler = new ConcurrentActionHandler();

// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {
    // You can add any common request handling logic here
    return config;
  },
  (error) => {
    // Handle request error here
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    // You can add any common response handling logic here
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const authenticated = Boolean(window.localStorage.getItem("authenticated"));
    if(error.response && error.response.status === 401 && authenticated) {
      window.localStorage.removeItem("authenticated");
      window.dispatchEvent(new Event("storage"));
    }

    if (error.response && statusCodeRequiresRefresh(error.response.status) && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        console.log("Refreshing token...");
        await concurrentActionHandler.execute(async () => await refreshAccessToken());
        console.log("Token refreshed, retrying original request...");
        return instance(originalRequest);
      } catch (err) {
        console.error("Token refresh failed:", err);
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

const statusCodeRequiresRefresh = (statusCode: number) => {
  return statusCode === 403;
}

async function refreshAccessToken() {
  try {
    const refreshInstance = axios.create({
      baseURL: authUrl,
      headers: {
        "Accept": "text/html",
        "Content-Type": "application/json",
      },
      responseType: "json",
      withCredentials: true,
    });

    const response = await refreshInstance.post("/refresh");

    if (response.status !== 204 || !response.data.token) {
      throw new Error("Unable to refresh token");
    }

    window.localStorage.setItem("authenticated", "true");
  } catch (error) {
    console.error("Token refresh failed:", error);
    throw error;
  }
}

export default instance;
