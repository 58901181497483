import React, { Suspense, useEffect, useState }  from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async"
import ProtectedRoute from "component/ProtectedRoute";
import 'component/app/App.css';
import UserProvider from 'provider/UserProvider';

const Login =  React.lazy(() => import("component/login/Login"));
const Dashboard =  React.lazy(() => import("component/dashboard/Dashboard"));
const Workspaces =  React.lazy(() => import("component/workspaces/Workspaces"));
const Application =  React.lazy(() => import("component/home/Application"));
const Header =  React.lazy(() => import("component/home/Header"));
const Flow =  React.lazy(() => import("component/flows/Flow"));
const Flows =  React.lazy(() => import("component/flows/Flows"));
const Triggers =  React.lazy(() => import("component/triggers/Triggers"));
const Actions =  React.lazy(() => import("component/actions/Actions"));
const Models =  React.lazy(() => import("component/models/Models"));
const Datasets =  React.lazy(() => import("component/datasets/Datasets"));
const Dataset =  React.lazy(() => import("component/datasets/Dataset"));
//const Endpoints =  React.lazy(() => import("component/endpoints/Endpoints"));
const Applications =  React.lazy(() => import("component/applications/Applications"));
const PlaygroundLive =  React.lazy(() => import("component/playground/PlaygroundLive"));
const Playgrounds =  React.lazy(() => import("component/playground/Playgrounds"));
const Playground =  React.lazy(() => import("component/playground/Playground"));
const Authenticated =  React.lazy(() => import("component/authenticated/Authenticated"));

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(Boolean(localStorage.getItem("authenticated")));

  const changeAuthentication = () => {
    console.log("changeAuthentication: window event " + Boolean(localStorage.getItem("authenticated")))
    setAuthenticated(Boolean(localStorage.getItem("authenticated")));
  };

  useEffect(() => {
    console.log("component did mount")
    window.addEventListener("storage", changeAuthentication);
    setAuthenticated(Boolean(localStorage.getItem("authenticated")));
    
    return () => {
      console.log("component will unmount")
      window.removeEventListener("storage", changeAuthentication);
    };
  }, []);

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="authenticated" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Authenticated />
            </Suspense>
          } />

          <Route path="login" element={
            <Suspense fallback={<div>Loading...</div>}>
              {authenticated ? <Navigate to="/" replace /> : <Login />}
            </Suspense>
          } />

          <Route element={<ProtectedRoute isAllowed={authenticated} />}>
            
            <Route path="dashboard" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Helmet>
                  <title>Dashboard | Nuraling</title>
                </Helmet>
                <Dashboard />
              </Suspense>
            } />

            <Route index element={
              <Header category={0} menu="" breadcrumb="home">
                <Helmet>
                  <title>Workspaces | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Workspaces />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/overview" element={
              <Header category={1} menu="overview" breadcrumb="application">
                <Helmet>
                  <title>Overview | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Application />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId" element={
              <Header category={1} menu="playground" breadcrumb="playgrounds">
                <Helmet>
                  <title>Playgrounds | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Playgrounds />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/playground" element={
              <Header category={1} menu="playgrounds" breadcrumb="playgrounds">
                <Helmet>
                  <title>Playgrounds | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Playgrounds />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/playground/:workflowId" element={
              <Header category={1} menu="playground" breadcrumb="playground">
                <Helmet>
                  <title>Playground | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <PlaygroundLive />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/playground-backup" element={
              <Header category={1} menu="playground" breadcrumb="playground">
                <Helmet>
                  <title>Playground | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Playground />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/flows" element={
              <Header category={1} menu="flows" breadcrumb="flows">
                <Helmet>
                  <title>Flows | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Flows />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/flow/:workflowId" element={
              <Header category={1} menu="flows" breadcrumb="flows">
                <Helmet>
                  <title>Flow | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Flow />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/triggers" element={
              <Header category={1} menu="triggers" breadcrumb="triggers">
                <Helmet>
                  <title>Triggers | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Triggers />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/actions" element={
              <Header category={1} menu="actions" breadcrumb="actions">
                <Helmet>
                  <title>Actions | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Actions />
                </Suspense>
              </Header>
            } />


            <Route path="application/:appId/models" element={
              <Header category={1} menu="models" breadcrumb="models">
                <Helmet>
                  <title>Models | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Models />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/datasets" element={
              <Header category={1} menu="datasets" breadcrumb="datasets">
                <Helmet>
                  <title>Datasets | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Datasets />
                </Suspense>
              </Header>
            } />

            <Route path="application/:appId/dataset/:datasetId" element={
              <Header category={1} menu="datasets" breadcrumb="datasets">
                <Helmet>
                  <title>Dataset | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Dataset />
                </Suspense>
              </Header>
            } />
            
            <Route path="workspace/:workspaceId" element={
              <Header category={0} menu="applications" breadcrumb="applications">
                <Helmet>
                  <title>Applications | Nuraling</title>
                </Helmet>
                <Suspense fallback={<div>Loading...</div>}>
                  <Applications />
                </Suspense>
              </Header>
            } />
          </Route>

          <Route path="*" element={<div>There's nothing here: 404!</div>} />

        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;