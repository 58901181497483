import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import apiManager from 'manager/ApiManager';
import { User } from 'context/UserContext';

// Interface for the context value
export interface UserContextValue {
  user: User | null;
  fetchUserData: () => void;
  setWorkspace: (workspace: string | null) => void;
  setWorkspaceId: (workspaceId: string | null) => void;
  setApplication: (application: string | null) => void;
  setApplicationId: (applicationId: string | null) => void;
}

// Create a context to hold user data
const UserContext = createContext<UserContextValue | null>(null);

interface UserProviderProps {
  children: React.ReactNode;
}

// UserProvider component
const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  // Ref to track login event
  const loginEventOccurredRef = useRef(false);

  // Fetch user data from the API
  const fetchUserData = useCallback(() => {
    loginEventOccurredRef.current = Boolean(localStorage.getItem("authenticated"))

    if (loginEventOccurredRef.current) {
      apiManager.get("/user")
        .then((response) => {
          setUser((prevUser) => ({
            ...response.data.data,
            workspace: prevUser ? prevUser.workspace : null,
            workspaceId: prevUser ? prevUser.workspaceId : null,
          }));
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setUser(null);
        });
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Method to set the workspace
  const setWorkspace = (workspace: string | null) => {
    setUser((prevUser) => ({
      ...prevUser!,
      workspace: workspace,
    }));
  };

  const setWorkspaceId = (workspaceId: string | null) => {
    setUser((prevUser) => ({
      ...prevUser!,
      workspaceId: workspaceId,
    }));
  };

  const setApplication = (application: string | null) => {
    setUser((prevUser) => ({
      ...prevUser!,
      application: application,
    }));
  };

  const setApplicationId = (applicationId: string | null) => {
    setUser((prevUser) => ({
      ...prevUser!,
      applicationId: applicationId,
    }));
  };

  // Create the context value object
  const contextValue: UserContextValue = {
    user,
    fetchUserData,
    setWorkspace,
    setWorkspaceId,
    setApplication,
    setApplicationId,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
export { UserContext };
