import React from 'react'
import {
  Navigate,
  Outlet,
} from 'react-router-dom';

const ProtectedRoute = ({
  isAllowed = false,
  redirectPath = '/login',
  children = undefined,
}) => {
  console.log("checking route: "+isAllowed)
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute