type SuccessCallback = () => void;
type ErrorCallback = (error: any) => void;

/**
 * Used when multiple React UI fragments attempt an action that needs to be performed only once.
 * JavaScript is single-threaded, so we do not need to lock as in other languages.
 */
export default class ConcurrentActionHandler {
  private callbacks: [SuccessCallback, ErrorCallback][] = [];

  /**
   * Run the supplied action once and return a promise while in progress.
   */
  public async execute(action: () => Promise<void>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const onSuccess: SuccessCallback = () => {
        resolve();
      };

      const onError: ErrorCallback = (error: any) => {
        reject(error);
      };

      this.callbacks.push([onSuccess, onError]);

      // Only do the work for the first UI view that calls us
      if (this.callbacks.length === 1) {
        this.performAction(action);
      }
    });
  }

  private async performAction(action: () => Promise<void>): Promise<void> {
    try {
      // Do the work
      await action();

      // On success resolve all promises
      this.callbacks.forEach((c) => {
        c[0]();
      });
    } catch (e) {
      // On failure resolve all promises with the same error
      this.callbacks.forEach((c) => {
        c[1](e);
      });
    } finally {
      // Reset once complete
      this.callbacks = [];
    }
  }
}
